import { ReactElement, useContext, useState } from "react";
import { DialogTrigger } from "react-aria-components";
import { Navigate, useNavigate } from "react-router-dom";
import { RoomSlotParams, Row } from "../components";
import { Box } from "../components/Box";
import { RoomModal } from "../components/RoomModal";
import { Table } from "../components/Table";
import { allowDrop, drop } from "../resources/libraries/utils";
import { RoomModel } from "../resources/models/RoomModel";
import { AppContext } from "./AppContextProvider";

export const Room = ({ room }: { room: RoomModel }) => {
  //da aggiungere link al db invece che room
  //il db sarà letto con listener per far scattare nuovamente il calcolo delle props ad ogni aggiornamento
  const navigate = useNavigate();
  const appCtx = useContext(AppContext);
  const user = appCtx.user;

  const [infoModal, setInfoModal] = useState(false);
  const [infoData, setInfoData] = useState<{
    title: string;
    description: string;
  }>({ title: "", description: "" });

  const infoPrepare = async (id: string) => {
    //id mi servirà per le info da caricare
    setInfoData({
      title: id,
      description: "Questa è la descrizione d'esempio..",
    });
    setInfoModal(true);
  };

  var rows: ReactElement[] = [];
  for (var y = 0; y < room.size.y; y++) {
    var slots: RoomSlotParams[] = [];
    for (var x = 0; x < room.size.x; x++) {
      const _x = x;
      const _y = y;
      const tablesFiltered = room.tables.filter(
        (t) => t.position.x === _x && t.position.y === _y
      );
      if (tablesFiltered.length > 1)
        throw new Error(
          "Tavoli in posizione ERRATA: più tavoli nella stessa posizione!"
        );
      const table =
        tablesFiltered.length === 1 ? (
          <Table
            id={tablesFiltered[0].id}
            position={tablesFiltered[0].position}
            connected={tablesFiltered[0].connected}
            key={tablesFiltered[0].id + y * x}
            locked={tablesFiltered[0].locked}
            reservation={tablesFiltered[0].reservation}
            secondary={tablesFiltered[0].secondary}
            onClick={infoPrepare}
          />
        ) : undefined;
      slots.push({
        children: table,
        draggable: false,
        rowId: y,
        columnId: x,
        onDrop: (event: any) => drop(event),
        onDragOver: (event: any) => allowDrop(event),
      });
    }
    rows.push(<Row children={slots} id={y}></Row>);
  }

  //CHECK di SICUREZZA
  if (appCtx.user === undefined) return <Navigate to="/login" />;
  return (
    <Box>
      <DialogTrigger isOpen={infoModal} onOpenChange={setInfoModal}>
        <RoomModal
          title={infoData?.title}
          description={infoData?.description}
        />
      </DialogTrigger>
      {user !== undefined && (
        <div id="divBox" className="squareBox">
          {rows}
        </div>
      )}
    </Box>
  );
};
