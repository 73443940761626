import { Auth, User, getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { Children } from "../components";
import appConf from "../configuration";

export const AppContext = createContext<{
  auth?: Auth;
  user?: User;
  setUser: (u: User | undefined) => void;
}>({ setUser: () => {} });

export function AppContextProvider({ children }: { children: Children }) {
  const auth = getAuth(appConf);
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    onAuthStateChanged(auth, (user) =>
      user ? setUser(user) : setUser(undefined)
    );
  }, [auth]);
  return (
    <AppContext.Provider value={{ auth: auth, user: user, setUser: setUser }}>
      {children}
    </AppContext.Provider>
  );
}
