import { ActionButton, Image } from "@adobe/react-spectrum";
import { signInWithEmailAndPassword } from "firebase/auth";
import { FormEvent, useContext, useState } from "react";
import {
  FieldError,
  Form,
  Input,
  Label,
  TextField,
} from "react-aria-components";
import { useNavigate } from "react-router-dom";
import { Box } from "../components/Box";
import { AppContext } from "./AppContextProvider";

export const Login = () => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const appCtx = useContext(AppContext);
  const auth = appCtx.auth;
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!auth) {
      setErrors("auth is undefined");
      return;
    }
    const data = Object.fromEntries(new FormData(e.currentTarget));
    signInWithEmailAndPassword(
      auth,
      data["email"].toString(),
      data["password"].toString()
    )
      .then(() => {
        // Signed in
        navigate("/");
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErrors(errorMessage);
      });
  };

  if (auth?.currentUser) navigate("/");
  return (
    <Box direction="column" alignContent="space-evenly" height="100vh">
      <Box>
        <></>
      </Box>
      <Box direction="column" alignItems="center" gap="2em">
        <Image src="logo192.png" alignSelf={"center"} alt="" />
        <Box height="40vh">
          <Form
            validationErrors={errors}
            onSubmit={onSubmit}
            style={{ rowGap: "1em" }}
          >
            <TextField name="email" isRequired>
              <Label>Email</Label>
              <Input />
              <FieldError />
            </TextField>
            <TextField name="password" type="password" isRequired>
              <Label>Password</Label>
              <Input />
              <FieldError />
            </TextField>
            <ActionButton type="submit" autoFocus>
              Accedi
            </ActionButton>
          </Form>
        </Box>
      </Box>
      <Box>
        <></>
      </Box>
    </Box>
  );
};
