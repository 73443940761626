import { createRoomSlotId } from "../resources/libraries/utils";
import { Children } from "./Children";

export type RoomSlotParams = {
  children: Children;
  draggable: boolean;
  rowId: number;
  columnId: number;
  onDrop: (event: any) => void;
  onDragOver: (event: any) => void;
};

export const RoomSlot = ({ ...props }: RoomSlotParams) => {
  return (
    <div
      id={createRoomSlotId(props.rowId, props.columnId)}
      className="square"
      onDrop={props.onDrop}
      onDragOver={props.onDragOver}
      key={createRoomSlotId(props.rowId, props.columnId)}
    >
      {props.children}
    </div>
  );
};
