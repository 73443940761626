import { drag } from "../resources/libraries/utils";
import { TableModel } from "../resources/models/TableModel";

export type TableProps = TableModel & { onClick: (id: string) => void };
export const Table = ({ ...props }: TableProps) => {
  const classes = "square table";
  return (
    <div
      id={props.id}
      draggable="true"
      onDragStart={(event) => drag(event, props.id)}
      className={props.locked ? classes + " locked" : classes}
      onClick={() => props.onClick(props.id)}
    >
      {!props.secondary && props.id}
    </div>
  );
};
