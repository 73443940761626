import { RoomSlot, RoomSlotParams } from "./RoomSlot";

export const Row = ({
  children,
  id,
}: {
  children: RoomSlotParams[];
  id: number;
}) => {
  return (
    <div className="squareRow" id={"Row" + id} key={"Row" + id}>
      {children.map((r) => (
        <RoomSlot
          children={r.children}
          draggable
          rowId={r.rowId}
          columnId={r.columnId}
          onDrop={r.onDrop}
          onDragOver={r.onDragOver}
        ></RoomSlot>
      ))}
    </div>
  );
};
