import { ExampleRoom } from "../../App/ExampleRoom";
import { TableModel, TablePosition } from "../models/TableModel";
import { applyTableMoveRule } from "./tablesFunctions";

export function allowDrop(ev: any) {
  ev.preventDefault();
}

export function drag(ev: any, id: any) {
  var el = document.getElementById(id);
  if (!el) throw new Error("Dragging something is not an element");
  if (el.draggable) ev.dataTransfer.setData("Node", ev.target.id);
}

export function drop(ev: any) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("Node");
  var target = ev.target;

  try {
    const tableDiv = document.getElementById(data);
    if (!tableDiv)
      throw new Error("Il tavolo con id: '" + data + "' è inesistente");
    const table: TableModel | undefined = ExampleRoom.tables.find(
      (t) => t.id === data
    );
    if (!table)
      throw new Error("Il tavolo con id: '" + data + "' è inesistente nel db");
    console.log(target.id);
    const newPos = decodeRoomSlotId(target.id);
    const oldPosId = createRoomSlotId(table.position.y, table.position.x);
    const oldPos = decodeRoomSlotId(oldPosId);
    if (!oldPos) throw new Error("Old position coordinates wrong");
    console.log("newPos: " + JSON.stringify(newPos));
    if (newPos) {
      table.position = newPos;
      console.log(JSON.stringify(table));
      if (applyTableMoveRule(table, ExampleRoom.tables)) {
        target.appendChild(tableDiv);
      } else {
        document.getElementById(oldPosId)?.appendChild(tableDiv);
        table.position = oldPos;
      }
    }
    //dopo sarà da scrivere nel DB e non da spostare. Sarò da scrivere nel db all'interno del metodo 'applyTableMoveRule'. La pagina si dovrà rigenerare non appena verrà scritto il db
    console.log(JSON.stringify(table));
  } catch (e) {
    console.log(e);
  }
}

export function blockSwitch(id: any) {
  var el = document.getElementById(id);
  if (!el) throw new Error("The element of id: '" + id + "' is not founded");
  el.draggable = el.draggable ? false : true;
  el.draggable ? el.classList.remove("locked") : el.classList.add("locked");
  return "draggable from " + !el.draggable + " to " + el.draggable;
}

export function enableInfoBox(id: any) {
  //chiamata per info tavolo #id
  var infoBox = document.getElementById("infoBox");
  if (!infoBox) throw new Error("The element of id: 'infoBox' is not founded");
  infoBox.style.display = "block";
  var infoContent = document.getElementById("infoContent");
  if (!infoContent)
    throw new Error("The element of id: 'infoContent' is not founded");
  const table: TableModel | undefined = ExampleRoom.tables.find(
    (t) => t.id === id
  );
  infoContent.innerHTML = JSON.stringify(table);
}
export function disableInfoBox() {
  var infoBox = document.getElementById("infoBox");
  if (!infoBox) throw new Error("The element of id: 'infoBox' is not founded");
  infoBox.style.display = "none";
}

export function createRoomSlotId(rowId: number, columnId: number): string {
  return "RSy" + rowId + "x" + columnId;
}

function decodeRoomSlotId(id: string): TablePosition | undefined {
  const splits = id.split("x", 2);
  const result = {
    x: Number(splits[1]),
    y: Number(splits[0].split("y", 2)[1]),
  };
  if (result.x >= 0 && result.y >= 0) return result;
  return undefined;
}
