import { RoomModel } from "../resources/models/RoomModel";
import { TableModel } from "../resources/models/TableModel";

const content: TableModel[] = [
  { id: "1", position: { x: 1, y: 2 }, connected: true, secondary: true },
  { id: "2", position: { x: 8, y: 2 }, locked: true },
  { id: "3", position: { x: 3, y: 5 } },
  { id: "4", position: { x: 4, y: 4 } },
  { id: "5", position: { x: 0, y: 0 } },
  { id: "6", position: { x: 1, y: 1 }, connected: true },
  { id: "7", position: { x: 5, y: 5 } },
  { id: "8", position: { x: 6, y: 6 } },
  { id: "9", position: { x: 8, y: 8 } },
  { id: "10", position: { x: 9, y: 9 } },
]; //dovranno essere caricati da db

export const ExampleRoom: RoomModel = {
  id: 13,
  name: "Sala interna",
  size: { x: 10, y: 10 },
  tables: content,
}; //dovrà essere caricata da db;
