import { TableModel, TablePosition } from "../models/TableModel";

export function applyTableMoveRule(
  tb: TableModel,
  tables: TableModel[]
): boolean {
  console.log(JSON.stringify(tables));
  if (
    tables.find(
      (t) => tb.id !== t.id && calcDistance(tb.position, t.position) === 0
    )
  )
    //caso che sia già occupata la posizione da un altro tavolo
    return false;
  const neighbors = tables.filter(
    (t) => calcDistance(tb.position, t.position) === 1
  );
  if (tb.connected) {
    //caso tavolo di un gruppo
    if (tb.secondary) {
      //caso tavolo gragario
      if (neighbors.length > 0) {
        //che si unisce a un altro gruppo
        if (neighbors.length === 1) makeLeader(neighbors[0]);
        return true;
      } else {
        //che si separa dal gruppo
        tb.connected = false;
        tb.secondary = false;
        return true;
      }
    }
    //caso tavolo capo che si sposta con l'intero gruppo
    //da gestire lo spostamento sul db
    return true;
  }
  //caso tavolo singolo
  if (neighbors.length > 0) {
    //che si unisce a un gruppo
    if (tb.reservation) {
      console.log("Non puoi unire un tavolo riservato ad un altro tavolo");
      return false;
    }
    if (neighbors.length === 1) makeLeader(neighbors[0]);
    tb.connected = true;
    tb.secondary = true;
    return true;
  } else {
    //che si sposta solamente
    return true;
  }
}

export function tableDrop(id: number, pos: TablePosition) {}

function calcDistance(t1: TablePosition, t2: TablePosition): number {
  return Math.abs(t1.x - t2.x) + Math.abs(t1.y - t2.y);
}
function makeLeader(t: TableModel) {
  t.connected = true;
}
