import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from "react-aria-components";

export const RoomModal = ({
  ...props
}: {
  title: string;
  description: string;
}) => {
  return (
    <ModalOverlay className="room-overlay">
      <Modal className="room-modal">
        <Dialog>
          {({ close }) => (
            <>
              <Heading slot="title">{props.title}</Heading>
              <p>{props.description}</p>
              <Button onPress={close}>Close</Button>
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};
