import { Children } from "./Children";

export const Box = ({
  ...props
}: {
  children: Children | Children[];
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  alignItems?:
    | "normal"
    | "stretch"
    | "center"
    | "start"
    | "end"
    | "self-start"
    | "self-end"
    | "flex-start"
    | "flex-end";
  gap?: "1em" | "2em" | "3em" | "0.5em";
  width?: string;
  height?: string;
  alignContent?:
    | "center"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch";
  margin?: "auto" | number;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: props.direction,
        alignItems: props.alignItems,
        columnGap: props.gap,
        rowGap: props.gap,
        height: props.height,
        width: props.width,
        justifyContent: props.alignContent,
        alignContent: props.alignContent,
        margin: props.margin,
      }}
    >
      {props.children}
    </div>
  );
};
