import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD26lBj9o65yGHqCR-O8ytehrzwaHfYhN8",
  authDomain: "gestore-prenotazioni.firebaseapp.com",
  projectId: "gestore-prenotazioni",
  storageBucket: "gestore-prenotazioni.appspot.com",
  messagingSenderId: "707584397952",
  appId: "1:707584397952:web:207678293147d4d541cc65",
  measurementId: "G-KD1RR03786",
};
const appConf = initializeApp(firebaseConfig);

export default appConf;
