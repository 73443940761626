import { ActionButton } from "@adobe/react-spectrum";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  ComboBox,
  FieldError,
  Form,
  Header,
  Input,
  Key,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Section,
  Text,
  TextField,
} from "react-aria-components";
import { Navigate, useNavigate } from "react-router-dom";
import { Box } from "../components/Box";
import { AppContext } from "./AppContextProvider";

export const ControlUi = () => {
  const navigate = useNavigate();
  const appCtx = useContext(AppContext);
  const auth = appCtx.auth;

  const name = appCtx.user?.displayName
    ? appCtx.user?.displayName
    : appCtx.user?.email;

  const [roomId, setRoomId] = useState<Key | null>(null);
  useEffect(() => {
    roomId !== null && loadRoom(roomId);
  }, [roomId]);
  function loadRoom(id: any) {
    alert(id);
  }
  //CHECK di SICUREZZA
  if (appCtx.user === undefined) return <Navigate to="/login" />;
  //le sale andranno prese dal db..
  return (
    <Box direction="column" height="90vh">
      <Box
        direction="row"
        alignContent="space-between"
        width="90vw"
        margin="auto"
      >
        <TextField>Bentornato {name}</TextField>
        <Form
          onSubmit={() => {
            navigate("/login");
            appCtx.setUser(undefined);
            auth?.signOut();
          }}
        >
          <ActionButton type="submit">Logout</ActionButton>
        </Form>
        <ComboBox onSelectionChange={setRoomId}>
          <Label>Sala</Label>
          <div>
            <Input />
            <Button>▼</Button>
          </div>
          <Text slot="description">Seleziona la sala</Text>
          <FieldError />
          <Popover>
            <ListBox selectionMode="single">
              <Section>
                <Header>Ossobuco</Header>
                <ListBoxItem id="1" textValue="Ossobuco interno">
                  SALA interna
                </ListBoxItem>
                <ListBoxItem id="2" textValue="Ossobuco esterno 1">
                  Esterno 1
                </ListBoxItem>
                <ListBoxItem id="3" textValue="Ossobuco esterno 2">
                  Esterno 2
                </ListBoxItem>
              </Section>
              <Section>
                <Header>Zio Pesce</Header>
                <ListBoxItem id="4" textValue="Zio Pesce interno">
                  SALA interna
                </ListBoxItem>
                <ListBoxItem id="5" textValue="Zio Pesce esterno">
                  Esterno
                </ListBoxItem>
              </Section>
            </ListBox>
          </Popover>
        </ComboBox>
        <Box width="max-content">
          <Form onSubmit={() => navigate("/room")}>
            <ActionButton type="submit" isDisabled={roomId === null}>
              Vai alla sala
            </ActionButton>
          </Form>
        </Box>
      </Box>
      <Box height="60vh">
        <Label></Label>
      </Box>
    </Box>
  );
};
